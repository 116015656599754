@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";

.root {
  display: grid;
  position: relative;
  grid-template-areas: spot;
}
.foreground {
  grid-area: spot;
  stroke-linecap: round;
}
.background {
  grid-area: spot;
  opacity: 0.1;
}
.children {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
