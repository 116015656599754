@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
}

.alert {
  flex: 0 0 auto;
  border-radius: 0;
}

.content {
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;
  justify-content: stretch;
  overflow-x: hidden;
  overflow-y: auto;
}
